import axios from 'axios'
import {  ErrorRequestLog } from '../types/types';
import { LOG_REQUST_ERROR } from './apiMethods';


const config =  (window as any).config;

const instance = axios.create({
    baseURL: config.apiUrl
})

instance.interceptors.response.use(res => res, error => {    
    const model:ErrorRequestLog = {
        Message: JSON.stringify(error)
    }
    
    axios.post(config.apiUrl + LOG_REQUST_ERROR, model).catch(() => {})
    return Promise.reject(error);
})


export default instance