import { ACTIVATEVIP_PHONECARRIERS } from './phoneCarries';

export type countryCodes = { label: string, value: string }

export const options: countryCodes[] = []
const values: String[] = []
var countrycodes =  ACTIVATEVIP_PHONECARRIERS.split(/[\;,.]+/)

for (let i = 0; i < countrycodes.length; i++){
    if(!values.includes(countrycodes[i + 1])){
    options.push({label: countrycodes[i] + " (" + countrycodes[i + 1] + ")", value: countrycodes[i + 1]})
    values.push(countrycodes[i + 1])}
    i++;
}