import React, { Component, Suspense } from 'react';
import './App.css';
import CardActivator from './Containers/CardActivator/CardActivator'

class App extends Component {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <CardActivator />
      </Suspense>
    );
  }
}

export default App;
