import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './i18n.ts'
import dotenv from "dotenv";
dotenv.config();

import logError from './errorLogger';
import findIndexPollyfill from './pollyfill/findindex';

window.onerror = logError

//pollyfills
findIndexPollyfill()

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
