import { CardActivationForm, InputElementType, Phone } from "../../types/types";
import { validate } from 'validate.js'
import { PhoneNumberUtil } from 'google-libphonenumber'

 //Add new prop to this object to create new input in form
export const cardActivationForm: CardActivationForm = {
    cardSerialNumber: {
        validate: (value: string) => {
            const pattern = /\d{9}/
            const validation = validate({ cardSerialNumber: value.replace(/-|\s/g, '') }, { cardSerialNumber: { format: pattern } })
            if (validation)
                return false
            else
                return true
        },
        labelId: 'cardSerialNumber',
        isRequired: true,
        type: InputElementType.cardNumber,
        show: true,
        errorLabelId: 'invalidCard'
    },
    firstName: {
        validate: (value: string) => {
            return value ? true : false
        },
        labelId: 'firstName',
        isRequired: true,
        type: InputElementType.text,
        show: true,
        errorLabelId: 'fieldRequired'
    },
    lastName: {
        validate: (value: string) => {
            return value ? true : false
        },
        labelId: 'lastName',
        isRequired: true,
        type: InputElementType.text,
        show: true,
        errorLabelId: 'fieldRequired'
    },
    emailAddress: {
        validate: (value: string) => {
            if (value) {
                const validation = validate({ emailAddress: value }, { emailAddress: { email: true } })
                if (validation)
                    return false
                else
                    return true
            }
            else
                return true
        },
        labelId: 'emailAddress',
        isRequired: false,
        type: InputElementType.email,
        show: true,
        errorLabelId: 'invalidEmail'
    },
    phone: {
        validate: (value: Phone) => {
            let rawNumber = value.countryCode + value.phone
            if (rawNumber.lastIndexOf('00') === 0) {
                rawNumber = rawNumber.substr(2, rawNumber.length)
            }
            if (!(rawNumber.lastIndexOf('+') === 0)) {
                rawNumber = '+' + rawNumber
            }

            try {
                const phoneUtil = PhoneNumberUtil.getInstance()
                const number = phoneUtil.parse(rawNumber)
                return phoneUtil.isValidNumber(number)
            } catch (error) {
                return false
            }
        },
        labelId: 'mobilePhone',
        isRequired: true,
        type: InputElementType.phone,
        defValue: {
            countryCode: '+1',
            phone: ''
        } as Phone,
        show: true,
        errorLabelId: 'invalidPhone'
    },
    sendCoupons: {
        validate: (value: string) => {
            return true
        },
        labelId: 'sendCouponsSuperClient',
        isRequired: false,
        type: InputElementType.checkbox,
        show: false,
        defValue: false
    },
}