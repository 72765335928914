import React from 'react'
import { Location } from '../../../types/types'
import styles from './LocationList.module.css'
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

export interface Props {
    locations: Array<Location>
}

const locationList = (props: Props) => {
    const { t, i18n } = useTranslation();

    const renderThumb = (style: any) => {
        const thumbStyle = {
            backgroundColor: '#8F8F8F',
            width: '4px',
            float: 'left'
        };

        return (
            <div
                style={{ ...style.style, ...thumbStyle }}
            />
        );
    }

    const renderTrack = (style: any) => {
        const trackStyle = {
            backgroundColor: '#BEBEBE',
            width: '4px',
            float: 'right',
            height: '100%',
            position: 'relative'
        };

        return (
            <div
                style={{ ...style.style, ...trackStyle }}
            />
        );
    }

    return (
        <>
            {props.locations.length <= 0 ? <p>{t('noRecords')}</p> :
                <div className={styles.LocationList}>
                    <Scrollbars renderThumbVertical={renderThumb} renderTrackVertical={renderTrack} thumbMinSize={241}>
                        {props.locations.map(val => (
                            <div className={styles.Location} key={val.Id}>
                                <p className={styles.LocationName}>{val.Name}</p>
                                <p className={styles.LocationAddress}>{val.Address}</p>
                            </div>
                        ))}
                    </Scrollbars>
                </div>
            }
        </>

    )
}

export default locationList