import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import axios from '../../utils/axios'

import smsvaletLogo from '../../assets/smsvaletlogo.svg'
import vipcardLogo from '../../assets/vipcardlogo.svg'
import styles from './CardActivator.module.css'

import { Superclient, VipCarFormElement, InputElementType, Phone, CardActivationForm, ApiResult, ActivateVipCard, ApiResultBase } from '../../types/types'
import InputElement from '../../Components/UI/InputElement/InputElement';
import LocationList from '../../Components/Location/LocationList/LocationList';
import { SUPERCLIENT_INFO, ACTIVATE_CARD } from '../../utils/apiMethods';

//Set new inputs here
import { cardActivationForm } from './CardActivationForm';


interface State {
    vipCardForm: Array<VipCarFormElement>,
    superclient?: Superclient,
    isActivated: boolean,
    isDuringActivation: boolean,
    superclientIsLoading: boolean,
    activationError?: string,
    superclientError?: string
}

interface Props extends WithTranslation {

}

class CardActivator extends React.Component<Props, State>{
    state: State = {
        vipCardForm: Object.keys(cardActivationForm)
            .map(val => {
                return {
                    id: val,
                    value: cardActivationForm[val].defValue !== undefined ? cardActivationForm[val].defValue : '',
                    isValid: !cardActivationForm[val].isRequired,
                    isTouched: false,
                    isSelected: false,
                    show: cardActivationForm[val].show
                } as VipCarFormElement
            }) as Array<VipCarFormElement>,
        superclient: undefined,
        isActivated: false,
        isDuringActivation: false,
        superclientIsLoading: false,
        activationError: undefined,
        superclientError: undefined
    }

    loadSuperclient = (cardSerialNumber: string) => {
        this.setState({ superclientIsLoading: true })
        axios.post<ApiResult<Superclient>>(SUPERCLIENT_INFO, { CardSerialNumber: cardSerialNumber.replace(/-|\s/g, '') }).then((resp) => {
            const superclient = resp.data.Data
            const newArray = [...this.state.vipCardForm]
            const index = newArray.findIndex((val) => val.id === 'sendCoupons');
            if (index > -1) {
                const oldValue = newArray[index]
                const newValue =
                {
                    ...oldValue,
                    labelParams: { superclient: superclient.Name },
                    show: true,
                    value: true
                } as VipCarFormElement
                newArray[index] = newValue;
            }
            this.setState({ superclient: superclient, vipCardForm: newArray, superclientIsLoading: false, superclientError: undefined })
        }).catch((resp) => {
            this.setState({ superclientIsLoading: false, superclientError: this.props.t('locationError') })
        })
    }

    activate = () => {
        const activateVipCard: ActivateVipCard = {
            CardSerialNumber: this.state.vipCardForm.find(val => val.id === 'cardSerialNumber')!.value.replace(/-|\s/g, ''),
            CountryCode: this.state.vipCardForm.find(val => val.id === 'phone')!.value.countryCode,
            Phone: this.state.vipCardForm.find(val => val.id === 'phone')!.value.phone,
            FirstName: this.state.vipCardForm.find(val => val.id === 'firstName')!.value,
            LastName: this.state.vipCardForm.find(val => val.id === 'lastName')!.value,
            Email: this.state.vipCardForm.find(val => val.id === 'emailAddress')!.value,
            SendCoupons: this.state.vipCardForm[this.state.vipCardForm.findIndex(val => val.id === 'sendCoupons')].value
        }
        this.setState({ isDuringActivation: true })
        axios.post<ApiResultBase>(ACTIVATE_CARD, activateVipCard).then((resp) => {
            this.setState({ isActivated: true, isDuringActivation: false, activationError: undefined })
        }).catch((resp) => {
            let error = null
            if (resp!.response) {
                switch (resp.response.data.Error.ErrorCode) {
                    case 2001:
                        error = this.props.t('cardNotValid')
                        break
                    case 2002:
                        error = this.props.t('cardNotFound')
                        break
                    case 2003:
                        error = this.props.t('cardActivatedLastName', { customerLastName: resp.response.data.Data.LastName })
                        break
                }
            }

            if (!error)
                error = this.props.t('serverError')

            this.setState({ isDuringActivation: false, activationError: error })
        })
    }

    onChangedHandler = (id: number, value: any) => {
        const newArray = [...this.state.vipCardForm]

        const oldValue = newArray[id];
        const newValue =
        {
            ...oldValue,
            value: value,
            isValid: cardActivationForm[oldValue.id].validate(value),
            isTouched: true
        }
        newArray[id] = newValue;

        this.setState({ vipCardForm: newArray })

        if (newValue.id === 'cardSerialNumber' && newValue.isValid) {
            this.loadSuperclient(value)
        }
    }

    onActivateHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (this.state.vipCardForm.
            map(val => val.isValid).
            reduce((previousValue, currentValue) => {
                return previousValue && currentValue
            }, true)) {
            this.activate()
        }
        else {
            const newArray = [...this.state.vipCardForm]
            for (let i = 0; i < newArray.length; i++) {
                newArray[i] = { ...newArray[i], isTouched: true }
            }
            this.setState({ vipCardForm: newArray })
        }
    }

    render() {
        let result = null
        let header = null
        let headerText = null
        let superclientLogo = null

        if (!this.state.isActivated) {

            const formElemenets = this.state.vipCardForm.map((val, index) => {
                const schemaElement = cardActivationForm[val.id]
                return (
                    <InputElement
                        key={val.id}
                        id={index}
                        type={schemaElement.type}
                        isRequired={schemaElement.isRequired}
                        label={this.props.t(schemaElement.labelId, val.labelParams)}
                        value={val.value}
                        onChanged={this.onChangedHandler}
                        isTouched={val.isTouched}
                        isValid={val.isValid}
                        show={val.show}
                        errorLabel={schemaElement.errorLabelId ? this.props.t(schemaElement.errorLabelId) : null}
                    />
                )
            })

            result = (
                <form onSubmit={this.onActivateHandler}>
                    {formElemenets}
                    <button className={styles.Activate} disabled={this.state.isDuringActivation}>{this.props.t('activate')}</button>
                    {this.state.activationError ? this.state.activationError.split('\n').map((item, i) => {
                        return <p className={styles.Error} key={i}>{item} </p>
                    }) : null}
                </form>
            )
            const headerClasses = [styles.HeaderHead, styles.Inline]
            headerText = this.props.t('vipCardActivationText')
            header =
                header = (
                    <>

                        <div className={styles.Header}>
                            <span>
                                <img className={styles.VipCardLogo} src={vipcardLogo} alt='SmsValetLogo' />
                                <h2 className={headerClasses.join(' ')}>
                                    {this.props.t('vipCardActivationHeader')}
                                </h2>
                            </span>
                            <p className={styles.HeaderText}>{headerText}</p>
                        </div>
                    </>
                )
        }
        else {
            if (this.state.superclientIsLoading)
                result = <p>{this.props.t('loading')}</p>
            else if (this.state.superclientError || !this.state.superclient)
                result = <p className={styles.Error}>{this.state.superclientError}</p>
            else {
                result = (<LocationList locations={this.state.superclient.Locations} />)
                superclientLogo = this.state.superclient.ImageUrl ? (<img className={styles.SuperclientLogo} src={this.state.superclient.ImageUrl} alt='Superclient' />) : null
            }

            headerText = this.props.t('vipCardCongratulationsText')
            header = (
                <>
                    <div>

                        <div className={styles.SuperclientLogo}>
                            {superclientLogo}
                        </div>
                        <div className={styles.HeaderWithImg}>
                            <h2 className={styles.HeaderHead}>
                                {this.props.t('vipCardCongratulationsHeader')}
                            </h2>
                            <p className={styles.HeaderText}>{headerText}</p>
                        </div>
                    </div>
                </>
            )
        }

        return (
            <div className={styles.CardActivator}>
                <div>
                    <img className={styles.Logo} src={smsvaletLogo} alt='SmsValetLogo' />
                    {header}
                </div>
                {result}
            </div>
        )
    }
}

export default withTranslation()(CardActivator)