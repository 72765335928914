export interface ApiResultBase {
    Success: boolean,
    Error?: Error
}

export interface Error {
    ErrorCode: number,
    Messages: Array<string>
}

export interface ApiResult<T> extends ApiResultBase {
    Data: T
}

export interface ActivateVipCard {
    CardSerialNumber: string,
    FirstName: string,
    LastName: string,
    Email: string,
    CountryCode: string,
    Phone: string,
    SendCoupons: boolean
}


export interface VipCarFormElement {
    id: string,
    value: any,
    isValid: boolean,
    isTouched: boolean,
    show: boolean,
    labelParams?: any
}

export interface Phone {
    countryCode: string,
    phone: string
}

export interface Superclient {
    Name: string,
    ImageUrl: string,
    Locations: Array<Location>
     
}

export interface Location {
    Id: string,
    Name: string,
    Address?: string     
}


export interface CardActivationForm {
    [key: string]: CardActivationFormlement
}

export interface CardActivationFormlement {
    validate(value: any): boolean
    labelId: string,
    isRequired: boolean,
    type: InputElementType,
    defValue?: any,
    show: boolean,
    errorLabelId?: string
}

export enum InputElementType {
    text,
    email,
    phone,
    cardNumber,
    checkbox
}

export interface ErrorLog {
    Message: string,
    StackTrace: string,
    Source: string
}

export interface ErrorRequestLog {
    Message: string
}