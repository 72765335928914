import React, { useState } from 'react'
import { InputElementType, Phone } from '../../../types/types';
import styles from './InputElement.module.css'
import Select, {SingleValue} from 'react-select';
import { countryCodes, options } from '../../../utils/countryCodes';

export interface Props {
    id: number,
    type: InputElementType,
    label: string,
    value: any,
    isRequired: boolean,
    onChanged(id: number, value: any): void,
    isValid: boolean,
    isTouched: boolean,
    show: boolean,
    errorLabel: string | null | undefined | object
}

const inputElement: React.FunctionComponent<Props> = (props) => {
    const [isFocused, setFocuse] = useState(false)

    const onFocuseHandler = (event: React.FormEvent<HTMLInputElement>) => {
        setFocuse(true)
    }

    const onFocuseOutHandler = (event: React.FormEvent<HTMLInputElement>) => {
        setFocuse(false)
    }

    const onCheckboxChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChanged(props.id, event.target.checked)
    }

    const onCardNumberChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChanged(props.id, event.target.value.replace(/(?!\d|-|\s)./g,''))
    }

    const onChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChanged(props.id, event.target.value)
    }

    const onPhoneChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phone = { ...props.value } as Phone
        phone.phone = event.target.value.replace(/(?!\d|-|\s)./g,'')
        props.onChanged(props.id, phone)
    }

    const onCountryCodeChangedHandler = (option: SingleValue<countryCodes>) => {
        if(option != null){
        const phone = { ...props.value } as Phone
        phone.countryCode = option.value
        props.onChanged(props.id, phone)}
    }

    let showLabel = true
    let input = null
    let classes = []
    let labelClasses = []
    let errorLabel

    if (!props.isValid && props.isTouched && !isFocused){
        classes.push(styles.Invalid)
        errorLabel = props.errorLabel
    }
    else{
        errorLabel = ''
    }


    switch (props.type) {
        case InputElementType.cardNumber:
            input = <input className={classes.join(' ')} type="text" onChange={onCardNumberChangedHandler} onFocus={onFocuseHandler} onBlur={onFocuseOutHandler} value={props.value} />
            break
        case InputElementType.email:
            input = <input className={classes.join(' ')} type="text" onChange={onChangedHandler} onFocus={onFocuseHandler} onBlur={onFocuseOutHandler} value={props.value} />
            break
        case InputElementType.phone:
            input = (
                <>  
                    <Select styles={{menu: (provided: any, state: any,) => ({...provided, marginTop: 5})}} className={classes.join(' ').concat(' ' + styles.CountryCode)} onChange={onCountryCodeChangedHandler} options={options} defaultValue={options[0]}/>
                    <input className={classes.join(' ').concat(' ' + styles.Phone)} type="text" onChange={onPhoneChangedHandler} onFocus={onFocuseHandler} onBlur={onFocuseOutHandler} value={props.value.phone} />
                </>
            )
            break
        case InputElementType.text:
            input = <input className={classes.join(' ')} type="text" onChange={onChangedHandler} onFocus={onFocuseHandler} onBlur={onFocuseOutHandler} value={props.value} />
            break
        case InputElementType.checkbox:
            input = (
                <span>
                    <label className={styles.Checkbox}>
                        <input type="checkbox" onChange={onCheckboxChangedHandler} onFocus={onFocuseHandler} onBlur={onFocuseOutHandler} checked={props.value} />
                        <span className={styles.CheckboxLabel}>{props.label}</span>
                        <span className={styles.Checkmark}></span>
                    </label>
                </span>
            )
            showLabel = false
            break
    }


    return (
        props.show ?
            <div className={styles.InputElement}>
                {showLabel ? <label>{props.label} {props.isRequired ? <span className={styles.Required}>*</span> : null}</label> : null}
                {input}
                <p className={styles.Error}>{errorLabel}</p>
            </div>
            : null
    )
}

export default inputElement