import axios from 'axios';
import { ErrorLog } from './types/types';
import { LOG_ERROR } from './utils/apiMethods';

const logError = (message:any, source:any, lineno:any, colno:any, error:any) => {
        const model:ErrorLog = {
            Message: message,
            StackTrace: error.stack,
            Source: source
        }

        axios.post(process.env.REACT_APP_API_URL + LOG_ERROR, model).catch(() => {})
}

export default logError